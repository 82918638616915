import React, { ReactElement, useMemo } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { ProductList } from '@types'
import { getProductList } from '@queries/products'
import { prop, sortBy } from '@utils/functional'
import { useQuery } from '@tanstack/react-query'

type Props = {
  value: number | undefined
  disabled?: boolean
  onChange(e): void
  className?: string
  containerProps?: any
  inputLabel?: string
  filter?: (products: ProductList) => ProductList
  productNameValue?: (product) => string
} & typeof defaultProps

const defaultProps = {
  filter: (products: ProductList) => products, // TODO: figure out how to use identity here w/ typescript
  disabled: false,
}

const ProductSelector = ({
  className,
  disabled,
  onChange,
  value,
  containerProps,
  filter,
  inputLabel = 'Product',
  productNameValue = (product) => product.name,
}: Props): ReactElement => {
  const { isLoading, data: products } = useQuery({
    queryKey: ['productList'],
    queryFn: getProductList,
    staleTime: 30000,
  })

  const filteredProducts = useMemo(
    () => filter(sortBy(prop('name'), products)),
    [products, filter],
  )

  const renderedSelect = useMemo(() => {
    return (
      <FormControl className={className} {...containerProps}>
        <InputLabel id='products-select-label'>{inputLabel}</InputLabel>
        <Select
          disabled={disabled}
          labelId='products-select-label'
          onChange={(e) => onChange(e.target.value)}
          value={value}
        >
          <MenuItem key={-1} value={-1}>
            <em>None</em>
          </MenuItem>
          {filteredProducts.map((product) => (
            <MenuItem key={product.id} value={product.id}>
              {productNameValue(product)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }, [filteredProducts, disabled, value, onChange])

  if (isLoading || !products) {
    return <div />
  }

  return renderedSelect
}

ProductSelector.defaultProps = defaultProps
export default ProductSelector
