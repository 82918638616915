import React, { useCallback, useEffect, useMemo, useState } from 'react'

import memoize from 'lodash.memoize'
import { Add, DeleteOutlined, EditOutlined } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'

import AddRecommendationDialog from './AddRecommendationDialog'
import DndTable from '@shared/dnd-table'
import Loader from '@shared/loader'
import ProductSelector from '@shared/product-selector'

import { getProductList } from '@queries/products'
import { selectRecommendations } from '@store/selectors'
import { useAllActions, useSelector } from '@hooks'
import { useQuery } from '@tanstack/react-query'
import EditRecommendationDialog from './EditRecommendationDialog'
import uniq from 'lodash.uniq'

const RecommendationEngine = () => {
  const { classes: styles } = useStyles()

  const { isLoading: loadingProducts, data: productList } = useQuery({
    queryKey: ['productList'],
    queryFn: getProductList,
    staleTime: 30000,
  })

  const {
    getRecommendations,
    setRecommendations,
    deleteRecommendation,
  } = useAllActions()

  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [editRecommendation, setEditRecommendation] = useState(null)

  useEffect(() => {
    getRecommendations()
  }, [])

  const recommendations = useSelector(selectRecommendations)

  const reSort = (data) => {
    const newData = data.map(({ id }, index) => {
      return { id, sort_order: index }
    })

    setRecommendations(newData)
  }

  const handleRowClick = useCallback((recommendationId) => {
    setEditRecommendation(recommendations[recommendationId])
  }, [recommendations])

  const handleEditClick = useCallback(({ original }) => {
    setEditRecommendation(original)
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Trigger',
        accessor: (row) =>
          productList.find((p) => {
            return p.id === row.product_id
          })?.name,
      },
      {
        Header: 'Recommendations',
        accessor: ({ recommended_product_ids: recommendedProductIds }) => ({
          recommendedProductIds,
        }),
        Cell: function renderProductSelector({ value: { recommendedProductIds, id } }) {
          return (
            <ol>
              {uniq(recommendedProductIds).map((productId) => {
                const product = productList.find((p) => p.id == productId)
                return (
                  <li key={ productId }>
                    { product?.name } ({ product?.square_sku })
                  </li>
                )
              })}
            </ol>
          )
        },
      }
    ]
  }, [productList])

  const actions = useMemo(() => {
    return [
      {
        title: 'Add Recommendation',
        icon: <Add />,
        toolbarAction: true,
        onClick: () => setAddDialogOpen(true),
      },
      {
        title: 'Edit row',
        icon: <EditOutlined />,
        toolbarAction: false,
        onClick: handleEditClick,
      },
      {
        title: 'Delete row',
        icon: <DeleteOutlined />,
        toolbarAction: false,
        onClick: (row) => deleteRecommendation(row.id),
      },
    ]
  }, [deleteRecommendation, setAddDialogOpen])

  if (loadingProducts) return <Loader />

  return (
    <div>
      <AddRecommendationDialog
        loading={false}
        open={addDialogOpen}
        recommendations={recommendations}
        setOpen={setAddDialogOpen}
      />
      <EditRecommendationDialog
        loading={false}
        recommendation={editRecommendation}
        onComplete={() => setEditRecommendation(null)}
      />
      <DndTable
        actions={actions}
        afterDrop={reSort}
        columns={columns}
        data={recommendations}
        rowClick={handleRowClick}
        sortColumn={['sort_order']}
        sortOrder={['asc']}
        title='Recommendation Engine'
      />
    </div>
  )
}

export default RecommendationEngine

const useStyles = makeStyles()(() => ({
  productSelector: {
    width: '100%',
    minWidth: 100,
  },
}))
