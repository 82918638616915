import * as api from '@utils/api'
import createAction from '@store/actions/create-action'
import {
  CREATE_RECOMMENDATION,
  CREATE_RECOMMENDATION_FAILURE,
  DELETE_RECOMMENDATION,
  DELETE_RECOMMENDATION_FAILURE,
  SET_RECOMMENDATION,
  SET_RECOMMENDATIONS,
  UPDATE_RECOMMENDATION,
  UPDATE_RECOMMENDATION_FAILURE,
} from '@store/action-list'
import { apiAction } from '@store/actions/api'
import { removeIndex, updateOrConcat } from '@utils/functional'

export const getRecommendations = () => async (dispatch) => {
  const { data: recommendations } = await api.GET('/p4/recommendations')

  return dispatch({
    type: SET_RECOMMENDATIONS,
    recommendations,
  })
}

export const getRecommendation = (id) => async (dispatch) => {
  const { data: recommendation } = await api.GET(`/p4/recommendations/${id}`)

  return dispatch({
    type: SET_RECOMMENDATION,
    recommendation,
  })
}

export const saveRecommendation = (recommendation) => async (dispatch) => {
  const type = SET_RECOMMENDATION
  const { data } = await api.PATCH(
    `/p4/recommendations/${recommendation.id}`,
    recommendation,
  )

  return dispatch({
    type,
    recommendation: data,
  })
}

export const setRecommendations =
  (sortedRecommendations) => async (dispatch) => {
    const recommendationsPayload = {
      recommendations: Object.values(sortedRecommendations),
    }

    const {
      data: { recommendations },
    } = await api.PATCH('/p4/recommendations/sort', recommendationsPayload)

    return dispatch({
      type: SET_RECOMMENDATIONS,
      recommendations,
    })
  }

export const deleteRecommendation = (recommendationId) => {
  return apiAction({
    url: `/p4/recommendations/${recommendationId}`,
    method: 'DELETE',
    actionCallbacks: {
      success: createAction(DELETE_RECOMMENDATION, () => ({
        payload: { recommendationId },
      })),
      failure: createAction(DELETE_RECOMMENDATION_FAILURE),
    },
  })
}

export const updateRecommendationIndex = (recommendation, index, id) => {
  const {
    id: recommendationId,
    recommended_product_ids: recommendedProductIds,
  } = recommendation

  const getUpdatedIds = () => {
    if (id === -1) return removeIndex(index, recommendedProductIds)

    return updateOrConcat(index, id, recommendedProductIds)
  }

  const updatedIds = getUpdatedIds()

  return apiAction({
    url: `/p4/recommendations/${recommendationId}`,
    method: 'PATCH',
    data: { recommended_product_ids: updatedIds },
    actionCallbacks: {
      success: createAction(UPDATE_RECOMMENDATION),
      failure: createAction(UPDATE_RECOMMENDATION_FAILURE),
    },
  })
}

export const updateRecommendation = (recommendation) => {
  return apiAction({
    url: `/p4/recommendations/${recommendation.id}`,
    method: 'PATCH',
    data: recommendation,
    actionCallbacks: {
      success: createAction(UPDATE_RECOMMENDATION),
      failure: createAction(UPDATE_RECOMMENDATION_FAILURE),
    },
  })
}

export const createRecommendation = (productId, sortOrder) => {
  return apiAction({
    url: `p4/recommendations`,
    method: 'POST',
    data: {
      product_id: productId,
      recommended_product_ids: [],
      sort_order: sortOrder,
    },
    actionCallbacks: {
      success: createAction(CREATE_RECOMMENDATION),
      failure: createAction(CREATE_RECOMMENDATION_FAILURE),
    },
  })
}
