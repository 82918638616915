import React, { ChangeEvent, ReactElement, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { POST } from '@utils/api'
import { PromotionUserType } from '@types'

type Props = {
  open: boolean
  onClose: () => void
  onReplaceUsers: (users: PromotionUserType[]) => void
}

const UploadAudience = ({
  open,
  onClose: handleClose,
  onReplaceUsers,
}: Props): ReactElement => {
  const [users, setUsers] = useState<PromotionUserType[]>([])
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [loading, setLoading] = useState(false)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    setLoading(true)
    processSpreadsheet(e.target.files[0])
  }

  const processSpreadsheet = async (file: File) => {
    setErrorMessage(null)
    const data = new FormData()
    data.append('audience', file)

    try {
      const { data: users }: { data: PromotionUserType[] } = await POST(
        `/p4/promotions/audience`,
        data,
      )
      setUsers(users)
    } catch (error: any) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleSave = () => {
    onReplaceUsers(users)
    handleClose()
  }

  return (
    <Dialog open={open}>
      <DialogTitle id='alert-dialog-title'>Upload An Audience</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a spreadsheet with a column filled with emails matching Ether
          users.
        </DialogContentText>
        <input disabled={loading} onChange={handleFileChange} type='file' />
        {users.length && !loading ? (
          <span>Users in Audience {users.length}</span>
        ) : null}
        {loading ? <span>Processing sheet...</span> : null}
        {errorMessage ? <span>{errorMessage}</span> : null}
      </DialogContent>
      <DialogActions>
        {users.length ? (
          <Button color='secondary' onClick={handleSave}>
            Save
          </Button>
        ) : null}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UploadAudience
