import createCachedSelector from 're-reselect'
import { ProductStoreType, ProductSummary } from '@types'

// TODO: replace with RootState when store is fully typed
type State = {
  ProductsStores: ProductStoreType[]
  Products: { [key: string]: ProductSummary }
}

/**
 * Returns a list of product_store for the given product
 */
const productsStoresSelector = (state: State) => state.ProductsStores
const productsSelector = (state: State) => state.Products

export const getProductsStoresForProduct = createCachedSelector(
  productsStoresSelector,
  (_: State, productId: number) => productId,
  (productsStores, productId) => {
    return productsStores.filter(
      (productStore) => productStore.product_id === productId,
    )
  },
)((_, productId) => productId)

export const getProductsStoresForStore = createCachedSelector(
  productsStoresSelector,
  (_: State, storeId: number) => storeId,
  (productsStores, storeId) => {
    return productsStores.filter(
      (productStore) => productStore.store_id === storeId,
    )
  },
)((_, storeId) => storeId)

/**
 * Returns a collection of products with included store details in productStore
 */
export const getProductsForStore = createCachedSelector(
  getProductsStoresForStore,
  productsSelector,
  (productsForStore, products) => {
    return productsForStore
      .map((productStore) => ({
        product: products[productStore.product_id],
        productStore,
      }))
      .filter((x) => x.productStore.archived_at === null)
  },
)((_, storeId) => storeId)

export const selectProduct = createCachedSelector(
  productsSelector,
  (_: State, productId: number) => productId,
  (products, productId) => products[productId],
)((_, productId) => productId)
