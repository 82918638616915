import NewCreditDialog from './credit-dialog'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import Table from '@components/shared/table'
import hasPerms from '@utils/has-permission'
import { Add } from '@mui/icons-material'
import { CreditType, UserType } from '@types'
import { Link } from 'react-router-dom'
import {
  createUserCredit,
  deleteUserCredit,
  getUserCredits,
  updateUserCredit,
} from '@queries/credits'

interface Props {
  user: UserType
}

const Credits = ({ user }: Props): ReactElement => {
  const [credits, setCredits] = useState<CreditType[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [creditEditValues, setCreditEditValues] = useState<{
    amount: number
    productType: string
    id: number | null
  }>({ amount: 0, productType: 'beverage', id: null })
  const canEdit = hasPerms('credits:update')

  const fetchCredits = useCallback(async () => {
    const credits = await getUserCredits(user.id)
    setCredits(credits)
  }, [user])

  useEffect(() => {
    fetchCredits()
  }, [fetchCredits])

  const orderRender = (credit) => {
    if (!credit.order_id) return 'Unclaimed'
    return (
      <Link to={`/users/${user.id}/orders/${credit.order_id}`}>
        #{credit.order_id}
      </Link>
    )
  }

  const handleAddCredit = useCallback(() => {
    setCreditEditValues({ amount: 0, productType: 'beverage', id: null })
    setDialogOpen(true)
  }, [])

  const handleCloseAddCredit = useCallback(() => {
    setDialogOpen(false)
  }, [])

  const createCredit = async ({
    amount,
    productType,
  }: {
    amount: number
    productType: string
  }) => {
    const newCredit = await createUserCredit(user.id, amount, productType)
    setCredits((previous) => [...previous, newCredit])
    setDialogOpen(false)
  }

  const updateCredit = async (
    { amount, productType }: { amount: number; productType: string },
    id: number,
  ) => {
    const updatedCredit = await updateUserCredit(
      user.id,
      id,
      amount,
      productType,
    )
    setCredits((previous) =>
      previous.map((c) => (c.id == updatedCredit.id ? updatedCredit : c)),
    )
    setDialogOpen(false)
  }

  const handleDeleteCredit = async (credit) => {
    await deleteUserCredit(user.id, credit.id)
    setCredits((previous) => previous.filter((c) => c.id != credit.id))
  }

  const handleUpdateCredit = async (credit) => {
    setCreditEditValues({
      amount: credit.amount,
      productType: credit.product_type,
      id: credit.id,
    })
    setDialogOpen(true)
  }

  const handleConfirm = async (creditValues: {
    amount: number
    productType: string
  }) => {
    if (creditEditValues.id) {
      return await updateCredit(creditValues, creditEditValues.id)
    }
    await createCredit(creditValues)
  }

  return (
    <>
      <NewCreditDialog
        creditValues={creditEditValues}
        onClose={handleCloseAddCredit}
        onConfirm={handleConfirm}
        open={dialogOpen}
      />
      <Table
        columns={[
          {
            field: 'amount',
            title: 'Amount',
          },
          {
            field: 'claimed',
            title: 'Claimed',
          },
          {
            field: 'product_type',
            title: 'Product Type',
          },
          {
            field: 'code',
            title: 'Code',
          },
          {
            field: 'order_id',
            title: 'Order',
            render: orderRender,
          },
        ]}
        data={credits}
        editing
        rowActions={{
          updatePredicate: (credit) => canEdit && !credit.order_id,
          deletePredicate: (credit) => canEdit && !credit.order_id,
          onRowUpdate: handleUpdateCredit,
          onRowDelete: handleDeleteCredit,
        }}
        rowKey='id'
        title='Customer Credits/Comps (used as a 1 time promotion code by customer)'
        toolbarButtons={
          canEdit
            ? [
                {
                  startIcon: <Add />,
                  title: 'New Credit',
                  onClick: handleAddCredit,
                },
              ]
            : []
        }
      />
    </>
  )
}

export default Credits
