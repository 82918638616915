import ProductSelector from '@shared/product-selector'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { fetchProduct } from '@services/product-service'
import { makeStyles } from 'tss-react/mui'
import { selectProduct } from '@store/selectors/products-stores'
import { useAllActions } from '@hooks/use-all-actions'
import { useDispatch } from 'react-redux'
import { useSelector } from '@hooks/use-selector'

interface Props {
  onComplete: () => void
  recommendation: any | null
}

const EditRecommendationDialog = ({ onComplete, recommendation }: Props) => {
  const { classes: styles } = useStyles()
  const dispatch = useDispatch()
  const { updateRecommendation } = useAllActions()
  const [recommendationIds, setRecommendationIds] = useState<number[]>([])

  useEffect(() => {
    if (recommendation) {
      fetchProduct(dispatch)(recommendation.product_id)
      setRecommendationIds(recommendation.recommended_product_ids)
    }
  }, [recommendation])

  const trigger = useSelector(selectProduct, recommendation?.product_id)

  const handleConfirm = useCallback(() => {
    updateRecommendation({
      ...recommendation,
      recommended_product_ids: recommendationIds,
    })
    onComplete()
  }, [recommendation, recommendationIds])

  const handleChangeProductId = (index) => (productId) => {
    setRecommendationIds(
      recommendationIds.with(index, productId).filter((x) => x != -1),
    )
  }

  const handleAppendProductId = (productId) => {
    setRecommendationIds(
      [...recommendationIds, productId].filter((x) => x != -1),
    )
  }

  const productSelectorNameValue = (product) =>
    `${product.name} (${product.square_sku})`

  return (
    <Dialog
      aria-labelledby='dialog-title'
      fullWidth
      open={recommendation != null}
    >
      <DialogTitle id='dialog-title'>
        Recommendations For {trigger?.name}
      </DialogTitle>
      {recommendationIds.map((productId, i) => (
        <ProductSelector
          className={styles.dialogField}
          inputLabel={`Product ${i + 1}`}
          key={i}
          onChange={handleChangeProductId(i)}
          productNameValue={productSelectorNameValue}
          value={productId}
        />
      ))}
      <ProductSelector
        className={styles.dialogField}
        inputLabel='Additional Product'
        onChange={handleAppendProductId}
        productNameValue={productSelectorNameValue}
        value={-1}
      />
      <DialogActions>
        <Button onClick={onComplete}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditRecommendationDialog

const useStyles = makeStyles()((theme) => ({
  dialogField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}))
