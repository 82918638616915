import * as api from '@utils/api'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'

type Props = {
  value: number | string
  onChange: (value: number) => void
  disabled?: boolean
  className?: string
  formControlProps?: Record<any, any>
}

type Account = {
  externalId: number
  defintion: string
}

const NetsuiteAccountSelect = ({
  value,
  onChange,
  disabled = false,
  formControlProps,
  ...selectProps
}: Props): ReactElement => {
  const [accounts, setAccounts] = useState<Account[] | null>(null)

  useEffect(() => {
    ;(async () => {
      const { data: accounts }: { data: Account[] } = await api.GET(
        '/p4/netsuite/accounts',
      )
      setAccounts(accounts)
    })()
  }, [])

  const handleOnChange = ({ target: { value } }: SelectChangeEvent<number>) =>
    onChange(value as number)

  const accountNumberValue =
    typeof value === 'string' ? parseInt(value, 10) : value

  return (
    <FormControl {...formControlProps}>
      <InputLabel id='netsuite-account-label'>Netsuite Account</InputLabel>
      {accounts && (
        <Select
          disabled={disabled}
          id='demo-simple-select'
          label='Netsuite Account'
          labelId='netsuite-account-label'
          onChange={handleOnChange}
          value={accountNumberValue}
          {...selectProps}
        >
          {accounts.map((account) => (
            <MenuItem key={account.externalId} value={account.externalId}>
              {account.defintion}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  )
}

export default NetsuiteAccountSelect
